import React, { ChangeEvent, KeyboardEvent, ReactElement, SetStateAction, useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import isEmpty from 'lodash.isempty';
import uniqueId from 'lodash.uniqueid';
import { LoadableButton } from '@mo/loadable-button';
import { PageErrorBox } from '@mo/page-error-box';
import { SimpleInput } from '@mo/simple-form';
import { PASSWORD_PAGE } from '../../../common/routes';
import { PageTitleWithTracking as Title } from '@mo/page-title';
import styles from './personalDetailsPage.module.scss';
import { DateType } from '../../../domain/Date';
import DateFormInput from '../../../components/dateFormInput/DateFormInput';
import { validate } from './validation/personalDetailsValidationSchema';
import { JobTitles } from './jobTitles';
import { JOB_TITLE_PLACEHOLDER } from '../../../domain/PersonalDetails';
import registrationContext from '../registrationContext';
import { PageError } from '../../../domain/PageError';
import { Checkbox } from '@mo/checkbox';
import { ExternalLink } from '@mo/external-link';
import { Dropdown, Option } from '@mo/dropdown';

export function PersonalDetailsPage(): ReactElement {
    const { personalDetails, setPersonalDetails } = useContext(registrationContext);

    const [validationErrors, setValidationErrors] = useState<{ [key: string]: string[] }>({});

    const [tcAccepted, setTcAccepted] = useState(false);

    const navigate = useNavigate();

    const handleSubmit = async (): Promise<void> => {
        try {
            setValidationErrors({});
            await validate({
                firstName: personalDetails.firstName,
                lastName: personalDetails.lastName,
                dob: personalDetails.dob,
                phoneNumber: personalDetails.phoneNumber,
                jobTitle: personalDetails.jobTitle,
                tcAccepted: tcAccepted,
            });
            navigate(PASSWORD_PAGE);
        } catch (errors) {
            setValidationErrors(errors as unknown as SetStateAction<{ [key: string]: string[] }>);
        }
    };

    const onKeyPress = async (event: KeyboardEvent<HTMLInputElement>): Promise<void> => {
        if (event.key === 'Enter') {
            return handleSubmit();
        }
    };

    const collateDobErrors = (errors: { [path: string]: string[] }): string[] | undefined => {
        if (errors) {
            const invalidDay = errors['dob.day'];
            const invalidMonth = errors['dob.month'];
            const invalidYear = errors['dob.year'];

            const errorMsg = [invalidDay, invalidMonth, invalidYear].filter((error): string[] => error).join(', ');

            return !isEmpty(errorMsg) ? [`Please enter a valid ${errorMsg}`] : errors.dob;
        }
    };

    const mapPageErrors = (errors: { [path: string]: string[] }): PageError[] => {
        const firstNameErrors = errors.firstName
            ? errors.firstName.map((error: string): PageError => ({ label: 'First name', message: error }))
            : [];

        const lastNameErrors = errors.lastName
            ? errors.lastName.map((error: string): PageError => ({ label: 'Last name', message: error }))
            : [];

        const dobErrors = collatedDobErrors
            ? collatedDobErrors.map((error: string): PageError => ({ label: 'Date of birth', message: error }))
            : [];

        const phoneNumberErrors = errors.phoneNumber
            ? errors.phoneNumber.map(
                  (error: string): PageError => ({
                      label: 'Mobile phone number',
                      selector: '[name="mobilePhoneNumberInput"]',
                      message: error,
                  }),
              )
            : [];

        const jobTitleErrors = errors.jobTitle
            ? errors.jobTitle.map((error: string): PageError => ({ label: 'Job title', message: error }))
            : [];

        const tcErrors = errors.tcAccepted
            ? errors.tcAccepted.map(
                  (error: string): PageError => ({
                      label: 'Terms and Conditions',
                      message: error,
                      selector: '[name="Terms-and-conditions"]',
                  }),
              )
            : [];

        return firstNameErrors
            .concat(lastNameErrors)
            .concat(dobErrors)
            .concat(phoneNumberErrors)
            .concat(jobTitleErrors)
            .concat(tcErrors);
    };

    const collatedDobErrors = collateDobErrors(validationErrors);
    const mappedPageErrors = mapPageErrors(validationErrors);

    const tcAcceptedError = validationErrors.tcAccepted !== undefined;

    return (
        <div className={styles.gridContainer}>
            <div className={styles.page}>
                <div className={styles.form}>
                    <div className={styles.formHeader}>
                        <PageErrorBox
                            className={styles.pageErrorBox}
                            hasErrors={!isEmpty(mappedPageErrors)}
                            errors={mappedPageErrors}
                        />
                        <div>
                            <Title text='Please provide some details about yourself' />
                        </div>
                    </div>
                    <div className={styles.formBody}>
                        <SimpleInput
                            type='text'
                            spellCheck='false'
                            groupClassName={styles.nameInput}
                            inputName='firstNameInput'
                            labelText='First name'
                            value={personalDetails.firstName}
                            onChange={(e: ChangeEvent<HTMLInputElement>): void => {
                                const firstName: string = e.target.value;
                                setPersonalDetails({ ...personalDetails, firstName });
                            }}
                            onKeyPress={onKeyPress}
                            inputFieldClassName={styles.nameInputField}
                            errors={validationErrors && validationErrors.firstName}
                        />

                        <SimpleInput
                            type='text'
                            autoComplete='family-name'
                            autoCorrect='off'
                            spellCheck='false'
                            groupClassName={styles.nameInput}
                            inputName='lastNameInput'
                            labelText='Last name'
                            value={personalDetails.lastName}
                            onChange={(e: ChangeEvent<HTMLInputElement>): void => {
                                const lastName: string = e.target.value;
                                setPersonalDetails({ ...personalDetails, lastName });
                            }}
                            onKeyPress={onKeyPress}
                            inputFieldClassName={styles.nameInputField}
                            errors={validationErrors && validationErrors.lastName}
                        />

                        <DateFormInput
                            labelText='Date of birth'
                            exampleText='For example: 21 04 1980'
                            hintText='This is required to confirm your identity if you call the Dealer Contact Services team.'
                            date={personalDetails.dob}
                            onChange={(newDob: DateType): void => {
                                setPersonalDetails({ ...personalDetails, dob: newDob });
                            }}
                            errors={collatedDobErrors}
                            handleSubmit={handleSubmit}
                        />

                        <SimpleInput
                            type='tel'
                            inputName='mobilePhoneNumberInput'
                            autoComplete='tel'
                            groupClassName={styles.mobileNumberInput}
                            labelText='Mobile phone number'
                            value={personalDetails.phoneNumber}
                            onChange={(e: ChangeEvent<HTMLInputElement>): void => {
                                const phoneNumber: string = e.target.value.trim();
                                setPersonalDetails({ ...personalDetails, phoneNumber });
                            }}
                            onKeyPress={onKeyPress}
                            inputFieldClassName={styles.mobileNumberInputField}
                            errors={validationErrors && validationErrors.phoneNumber}
                            optional={true}
                        />

                        <div className={styles.jobTitleDropdown}>
                            <Dropdown
                                className={styles.jobTitleDropdownSelect}
                                fieldName='job-titles'
                                placeholder={JOB_TITLE_PLACEHOLDER}
                                id={uniqueId('job-titles-')}
                                label='Job title'
                                labelClassName={styles.jobTitleLabel}
                                hint='This is your current role at the dealership(s) you are assigned to.'
                                value={{
                                    label: personalDetails.jobTitle,
                                    value: personalDetails.jobTitle,
                                }}
                                options={JobTitles.sort().map((jobTitle): { label: string; value: string } => {
                                    return {
                                        label: jobTitle,
                                        value: jobTitle,
                                    };
                                })}
                                onChange={(option: Option): void => {
                                    setPersonalDetails({ ...personalDetails, jobTitle: option.value });
                                }}
                                onKeyPress={onKeyPress}
                                errors={validationErrors?.jobTitle}
                            />
                        </div>
                        {tcAcceptedError ? (
                            <div className={styles.tcErrorHighlight}>
                                <p className={styles.tcTitle}>
                                    <strong> Terms and conditions </strong>
                                </p>
                                <p className={styles.tcInstructionText}>
                                    Please read and confirm your acceptance to the{' '}
                                    <ExternalLink
                                        href='https://motabilityprodstorage.blob.core.windows.net/drcuploads/general/Mar-2024-Motability-Dealer-Partnership-Programme-Website-and-general-terms-and-conditions.pdf'
                                        analyticsTag='website-terms-and-conditions'
                                        withoutIcon
                                    >
                                        Terms and Conditions
                                    </ExternalLink>
                                </p>
                                <p className={styles.tcError}>
                                    <strong> Please accept the terms and conditions </strong> <br></br>
                                </p>

                                <Checkbox
                                    onCheckChange={(checked): void => {
                                        setTcAccepted(checked);
                                    }}
                                    label='I have read and agree to the Terms and Conditions'
                                    isChecked={tcAccepted}
                                    labelClass={styles.tcCheckbox}
                                />
                            </div>
                        ) : (
                            <div>
                                <p className={styles.tcTitle}>
                                    <strong> Terms and conditions </strong>
                                </p>
                                <p className={styles.tcInstructionText}>
                                    Please read and confirm your acceptance to the{' '}
                                    <ExternalLink
                                        href='https://motabilityprodstorage.blob.core.windows.net/drcuploads/general/Mar-2024-Motability-Dealer-Partnership-Programme-Website-and-general-terms-and-conditions.pdf'
                                        analyticsTag='website-terms-and-conditions'
                                        withoutIcon
                                    >
                                        Terms and Conditions
                                    </ExternalLink>
                                </p>

                                <Checkbox
                                    onCheckChange={(checked): void => {
                                        setTcAccepted(checked);
                                    }}
                                    label='I have read and agree to the Terms and Conditions'
                                    isChecked={tcAccepted}
                                />
                            </div>
                        )}

                        <LoadableButton
                            loadingText=''
                            notLoadingText='Continue'
                            onClick={handleSubmit}
                            className={styles.continueButton}
                            loading={false}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
}
