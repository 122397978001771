export const HOME_PAGE = `/`;
export const REGISTRATION_PATH = `/registration`;

export const PERSONAL_DETAILS_PAGE_RELATIVE = `/personal-details`;
export const LINK_INVALID_PAGE_RELATIVE = `/invalid`;
export const PASSWORD_PAGE_RELATIVE = `/password`;
export const SUCCESS_PAGE_RELATIVE = `/success`;
export const ACCOUNT_ALREADY_EXISTS_PAGE_RELATIVE = `/account-exists`;

export const PERSONAL_DETAILS_PAGE = `${REGISTRATION_PATH}${PERSONAL_DETAILS_PAGE_RELATIVE}`;
export const LINK_INVALID_PAGE = `${REGISTRATION_PATH}${LINK_INVALID_PAGE_RELATIVE}`;
export const PASSWORD_PAGE = `${REGISTRATION_PATH}${PASSWORD_PAGE_RELATIVE}`;
export const SUCCESS_PAGE = `${REGISTRATION_PATH}${SUCCESS_PAGE_RELATIVE}`;
export const ACCOUNT_ALREADY_EXISTS_PAGE = `${REGISTRATION_PATH}${ACCOUNT_ALREADY_EXISTS_PAGE_RELATIVE}`;

export const EMAIL_ENTRY_PAGE = `/email-entry`;
export const EMAIL_CONFIRMATION_PAGE = `/email-confirmation`;
