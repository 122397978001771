import React, { FunctionComponent, ReactElement, useContext, useState } from 'react';
import isEmpty from 'lodash.isempty';

import { LoadableButton } from '@mo/loadable-button';
import { PageErrorBox } from '@mo/page-error-box';

import PasswordInput from './passwordInput/PasswordInput';

import styles from './createPasswordPage.module.scss';
import { ACCOUNT_ALREADY_EXISTS_PAGE, PERSONAL_DETAILS_PAGE, SUCCESS_PAGE } from '../../../common/routes';
import { PageTitleWithTracking as Title } from '@mo/page-title';
import { AccountAlreadyExistsError, GENERIC_PASSWORD_ERROR, PasswordValidationErrors } from './errors';
import { registerUserProfile } from './registerUserProfileGateway';

import Padlock from '../../../components/padlock/Padlock';

import { BackButton } from '@mo/back-button';
import { TechnicalError } from '../../../components/technicalError/TechnicalError';
import { ApplicationError } from '../../../common/errors';
import registrationContext from '../registrationContext';
import { PageError } from '../../../domain/PageError';
import { useNavigate } from 'react-router-dom';

const CreatePasswordPage: FunctionComponent = (): ReactElement => {
    const { token, personalDetails, setUserInfo } = useContext(registrationContext);

    const BLANK_OR_INVALID_PASSWORD = 'Please enter a password that meets the requirements';
    const INVALID_CHARACTER =
        'The password you entered contains an invalid character. Please review your password and try again';
    const PASSWORD_TOO_LONG =
        'The password you entered is too long. Please enter a password that is not more than 64 characters';

    const passwordRegexp = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,}$)/;
    // Not sure why eslint complains, this regex matches any non-ascii character
    // eslint-disable-next-line no-control-regex
    const nonAsciiRegex = /[^\x00-\x7F]/;

    const [password, setPassword] = useState<string>('');
    const [technicalError, setTechnicalError] = useState<boolean>(false);
    const [passwordValidationError, setPasswordValidationError] = useState<string[]>([]);
    const [loading, setLoading] = useState<boolean>(false);
    const navigate = useNavigate();

    const { firstName, lastName, dob, phoneNumber, jobTitle } = personalDetails;

    const handleSubmit = async (): Promise<void> => {
        if (password.length === 0) return setPasswordValidationError([BLANK_OR_INVALID_PASSWORD]);
        if (password.length > 64) return setPasswordValidationError([PASSWORD_TOO_LONG]);
        if (nonAsciiRegex.test(password)) return setPasswordValidationError([INVALID_CHARACTER]);
        if (!passwordRegexp.test(password)) return setPasswordValidationError([BLANK_OR_INVALID_PASSWORD]);

        try {
            setLoading(true);

            const { username, id, email } = await registerUserProfile({
                token,
                firstName,
                lastName,
                password,
                dob,
                phoneNumber,
                jobTitle,
            });

            setUserInfo({ username, id, email });
            setLoading(false);
            navigate(SUCCESS_PAGE);
        } catch (error) {
            setLoading(false);
            if (error instanceof PasswordValidationErrors) {
                return setPasswordValidationError(error.validationErrors);
            }

            if (error instanceof AccountAlreadyExistsError) {
                setLoading(false);
                navigate(ACCOUNT_ALREADY_EXISTS_PAGE);
            }

            if (error instanceof ApplicationError) {
                setTechnicalError(true);
            }
        }
    };

    const onPasswordChange = (newPassword: string): void => {
        setPasswordValidationError([]);
        setPassword(newPassword);
    };

    const mapPasswordValidationPageErrors = (validationErrors: string[]): PageError[] => {
        return validationErrors.map((validationError: string): PageError => {
            return { label: 'Password', message: validationError };
        });
    };

    const mapPasswordValidationInlineErrors = (validationErrors: string[]): string | undefined => {
        if (validationErrors.length > 1) return GENERIC_PASSWORD_ERROR;
        else if (validationErrors.length === 1) return validationErrors[0];
    };

    const errors = mapPasswordValidationPageErrors(passwordValidationError);

    const hasErrors = !isEmpty(errors);

    return (
        <div className={styles.gridContainer}>
            <div className={styles.page}>
                <div className={styles.form}>
                    <div className={styles.formHeader}>
                        <PageErrorBox hasErrors={hasErrors} errors={errors} />
                        <div className={hasErrors ? styles.formHeaderContentInError : ''}>
                            <BackButton
                                onClick={(): void => navigate(PERSONAL_DETAILS_PAGE, { replace: true })}
                                text='Back'
                                role={'link'}
                            />
                            <Title text={'Create a password'} />
                            <p className={styles.instructionText}>
                                {`Please create a secure password to sign in to Online Applications.`}
                            </p>
                        </div>
                    </div>
                    <div className={styles.formBody}>
                        <div className={styles.passwordEntryContainer}>
                            <div className={styles.passwordEntryForm}>
                                <PasswordInput
                                    password={password}
                                    error={mapPasswordValidationInlineErrors(passwordValidationError)}
                                    onChange={onPasswordChange}
                                    handleSubmit={handleSubmit}
                                />
                                {technicalError && <TechnicalError />}
                                <LoadableButton
                                    loading={loading}
                                    loadingText=''
                                    notLoadingText='Confirm'
                                    onClick={handleSubmit}
                                    className={styles.continueButton}
                                />
                            </div>
                            <Padlock className={styles.padlock} />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default CreatePasswordPage;
