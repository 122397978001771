import React, { ReactElement, useEffect, useState } from 'react';
import { Route, Routes, useNavigate } from 'react-router-dom';

import {
    ACCOUNT_ALREADY_EXISTS_PAGE_RELATIVE,
    LINK_INVALID_PAGE,
    LINK_INVALID_PAGE_RELATIVE,
    PASSWORD_PAGE_RELATIVE,
    PERSONAL_DETAILS_PAGE,
    PERSONAL_DETAILS_PAGE_RELATIVE,
    SUCCESS_PAGE_RELATIVE,
} from '../../common/routes';
import RegistrationContext from './registrationContext';
import { PersonalDetailsPage } from './personalDetails/PersonalDetailsPage';
import CreatePasswordPage from './password/CreatePasswordPage';
import SuccessPage from './success/SuccessPage';
import AccountAlreadyExistsPage from './accountAlreadyExists/AccountAlreadyExistsPage';
import { blankPersonalDetails, PersonalDetails } from '../../domain/PersonalDetails';
import { blankUserInfo, UserInfo } from '../../domain/UserInfo';
import { InvalidLinkPage } from './invalidLink/InvalidLinkPage';
import { useQueryParam } from '@mo/hooks';

function RegistrationRouter(): ReactElement {
    const { token: tokenParam } = useQueryParam(window.location);
    const navigate = useNavigate();
    const [token, setToken] = useState<string>('');
    const [personalDetails, setPersonalDetails] = useState<PersonalDetails>(blankPersonalDetails);
    const [userInfo, setUserInfo] = useState<UserInfo>(blankUserInfo);

    useEffect(() => {
        if (!token) {
            setToken(tokenParam || '');

            navigate(tokenParam ? PERSONAL_DETAILS_PAGE : LINK_INVALID_PAGE, { replace: true });
        }
    }, [navigate, token, tokenParam]);

    const value = { token, personalDetails, setPersonalDetails, userInfo, setUserInfo };

    return (
        <RegistrationContext.Provider value={value}>
            <Routes>
                <Route path={PERSONAL_DETAILS_PAGE_RELATIVE} element={<PersonalDetailsPage />} />
                <Route path={PASSWORD_PAGE_RELATIVE} element={<CreatePasswordPage />} />
                <Route path={SUCCESS_PAGE_RELATIVE} element={<SuccessPage />} />
                <Route path={LINK_INVALID_PAGE_RELATIVE} element={<InvalidLinkPage />} />
                <Route path={ACCOUNT_ALREADY_EXISTS_PAGE_RELATIVE} element={<AccountAlreadyExistsPage />} />
            </Routes>
        </RegistrationContext.Provider>
    );
}

export default RegistrationRouter;
