import React, { ReactElement, useContext, useEffect } from 'react';
import { BackButton } from '@mo/back-button';
import { PageTitleWithTracking as Title } from '@mo/page-title';
import styles from './emailConfirmationPage.module.scss';
import { Link, useNavigate } from 'react-router-dom';
import { EMAIL_ENTRY_PAGE, HOME_PAGE } from '../../../common/routes';
import verificationContext from '../verificationContext';

export function EmailConfirmationPage(): ReactElement {
    const { email } = useContext(verificationContext);
    const navigate = useNavigate();

    useEffect(() => {
        if (!email) {
            navigate(HOME_PAGE, { replace: true });
            return;
        }
    });

    return (
        <div className={styles.gridContainer}>
            <div className={styles.page}>
                <div className={styles.content}>
                    <BackButton text={'Back'} onClick={() => {
                        navigate(-1)
                    }} role={'link'} />
                    <Title text={'Please check your email'} className={styles.title} />
                    <p className={styles.instructions}>
                        An email has been sent to <strong> {email} </strong> with instructions on how to proceed.
                    </p>
                    <h2 className={styles.instructionsHeader}>Didn't receive anything?</h2>
                    <p className={styles.instructions}>
                        Check your spam folder, or <Link to={EMAIL_ENTRY_PAGE}>enter your email address again</Link>.
                    </p>
                </div>
            </div>
        </div>
    );
}
