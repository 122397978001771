import React, {
    ChangeEvent,
    KeyboardEvent,
    ReactElement,
    SetStateAction,
    useContext,
    useEffect,
    useState,
} from 'react';
import { useNavigate } from 'react-router-dom';
import { LoadableButton } from '@mo/loadable-button';
import { EMAIL_CONFIRMATION_PAGE, HOME_PAGE } from '../../../common/routes';
import { PageTitleWithTracking as Title } from '@mo/page-title';
import styles from './emailEntryPage.module.scss';
import { BackButton } from '@mo/back-button';
import { SimpleInput } from '@mo/simple-form';
import { validateEmail } from './validation/emailValidationSchema';
import { PageErrorBox } from '@mo/page-error-box';
import isEmpty from 'lodash.isempty';
import { LinkableErrorMessageProps } from '@mo/page-error-box/dist/PageErrorBox';
import { EMAIL_DOMAIN_NOT_ALLOWED } from './validation/errors';
import verificationContext, { initialUsedOlaBefore } from '../verificationContext';
import appContext from '../../../common/appContext';
import { initiateRegistration } from './initiateRegistrationGateway';
import { ServiceErrorMessage } from '@mo/service-error-message';

export function EmailEntryPage(): ReactElement {
    const { config } = useContext(appContext);
    const { usedOlaBefore, setEmail } = useContext(verificationContext);

    const [validationErrors, setValidationErrors] = useState<{ [key: string]: string[] }>({});
    const [loading, setLoading] = useState<boolean>(false);
    const [technicalError, setTechnicalError] = useState<boolean>(false);

    const [workEmail, setWorkEmail] = useState('');
    const navigate = useNavigate();

    useEffect(() => {
        if (usedOlaBefore === initialUsedOlaBefore) {
            navigate(HOME_PAGE, { replace: true });
        }
    }, [navigate, usedOlaBefore]);

    const handleSubmit = async (): Promise<void> => {
        try {
            setTechnicalError(false);
            setValidationErrors({});
            await validateEmail({ email: workEmail });
            await submitEmail(workEmail);
        } catch (err) {
            const error = err as unknown as SetStateAction<{ [key: string]: string[] }>;
            setValidationErrors(error);
        }
    };

    const submitEmail = async (email: string): Promise<void> => {
        try {
            setLoading(true);
            await initiateRegistration({ email });
            setEmail(workEmail);
            navigate(EMAIL_CONFIRMATION_PAGE);
        } catch (error) {
            setTechnicalError(true);
            setLoading(false);
        }
    };

    const onKeyPress = async (event: KeyboardEvent<HTMLInputElement>): Promise<void> => {
        if (event.key === 'Enter') {
            return handleSubmit();
        }
    };

    const mapPageErrors = (errors: { [path: string]: string[] }): LinkableErrorMessageProps[] => {
        if (errors.email) {
            return errors.email.map(
                (error: string): LinkableErrorMessageProps => ({
                    label: 'Dealership email address',
                    message:
                        error === EMAIL_DOMAIN_NOT_ALLOWED ? (
                            <>
                                {error} If you are unable to register a work email address with your dealership please
                                call us on <a href={'tel:0300 037 0200'}>0300 037 0200</a>.
                            </>
                        ) : (
                            error
                        ),
                }),
            );
        }

        return [];
    };

    const mappedPageErrors = mapPageErrors(validationErrors);

    return (
        <div className={styles.gridContainer}>
            <div className={styles.page}>
                <div className={styles.content}>
                    <BackButton text={'Back'} onClick={(): void => navigate(-1)} role={'link'} />
                    <PageErrorBox
                        className={styles.pageErrorBox}
                        hasErrors={!isEmpty(mappedPageErrors)}
                        errors={mappedPageErrors}
                    />
                    <Title text={'Please enter your email address'} />
                    <p className={styles.instructions}>
                        Please provide your individual dealership email address. We are unable to register accounts with
                        shared or personal email addresses.
                    </p>
                    <SimpleInput
                        groupClassName={styles.workEmail}
                        inputName={'dealershipEmailInput'}
                        labelText={'Dealership email address'}
                        value={workEmail}
                        onChange={(e: ChangeEvent<HTMLInputElement>): void => {
                            const email: string = e.target.value;
                            setWorkEmail(email);
                        }}
                        onKeyPress={onKeyPress}
                        inputFieldClassName={styles.workEmailInputField}
                        labelClassName={styles.workEmailLabel}
                        errors={validationErrors.email}
                        type={'email'}
                        autoComplete={'email'}
                    />
                    {technicalError && (
                        <ServiceErrorMessage
                            title='Our fault - a technical error happened'
                            visible={true}
                            className={styles.emailEntryTechnicalError}
                        >
                            <p>
                                Sorry, we’re having some problems saving your email address. Please refresh the page and
                                try again in a few moments. If this problem persists please call us on{' '}
                                <a href={'tel:0300 037 0200'}>0300 037 0200</a>.
                            </p>
                        </ServiceErrorMessage>
                    )}
                    <LoadableButton
                        loadingText='Submitting...'
                        notLoadingText='Continue'
                        onClick={handleSubmit}
                        className={styles.continueButton}
                        loading={loading}
                    />
                    <LoadableButton
                        loadingText=''
                        notLoadingText='Back to sign in'
                        onClick={(): void => window.location.replace(config.signInUrl)}
                        className={styles.cancelButton}
                        loading={false}
                        role={'link'}
                    />
                </div>
            </div>
        </div>
    );
}
